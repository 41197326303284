@import "origami-font.scss";
@import "material-icons-font.scss";
@import "variables.scss";

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&amp;subset=cyrillic");

.mat-expansion-panel-body {
  padding: 0 !important;
}

html {
  * {
    outline: none;
    font-family: "Roboto", sans-serif;
    transition: background-color 500ms;
    box-sizing: border-box;
    font-display: swap;
  }

  font-size: 14px;
}

body {
  margin: 0;
}

.counter .mat-button-wrapper {
  line-height: 0 !important;
}

.login-form .mat-form-field-label {
  color: white !important;
}

.login-form .mat-input-element {
  color: white !important;
}

.login-form .mat-error {
  color: #ffab40 !important;
}

/* .mat-card-image {
    margin: 0 !important;
}
 */

.mat-tab-body-content {
  overflow: unset;
}

.products-list .mat-tab-body {
  min-height: 100vh;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.order-form-dialog {
  height: 650px;
  width: 400px;
  max-width: 100vw !important;
  max-height: 100vh !important;
}

.cart-button_lt-md {
  .mat-badge-content {
    font-size: 1rem !important;
  }
}

.cart-list {
  .mat-list-item-content {
    position: unset !important;
  }

  .mat-icon-button {
    position: unset !important;
  }
}

.edit-product-dialog {
  overflow: scroll !important;
}

.mat-card-header-text {
  margin: 0 !important;
}

.origami-dark-theme {
  app-products-list > div.products-list {
    background: #151519;
  }

  app-main > div.wrapper {
    background: #151519;
  }

  app-add-product-to-cart-button button {
    color: white !important;
    background-color: transparent !important;
  }

  app-home {
    color: white;

    .info-icons {
      background: #151519;
    }

    > div.wrapper {
      background: #151519;
    }
  }

  app-discounts {
    color: white;

    > .wrapper {
      background: #151519;
    }
  }

  nav.mat-tab-nav-bar {
    background: #151519 !important;

    .mat-tab-link {
      opacity: 1 !important;
    }
  }

  .mat-ink-bar {
    background-color: $light-primary !important;
  }

  mat-dialog-container {
    background: #151519 !important;
  }

  mat-horizontal-stepper {
    background-color: #151519 !important;
  }

  mat-card {
    background: #151519 !important;

    &.cart {
      background: #1d1d1d;
    }
  }
}

.discount-details-dialog {
  mat-dialog-container {
    padding: 5px;
  }
}

/* .product-details-dialog {
  mat-dialog-container {
    overflow-y: scroll;
  }
} */

.mat-drawer-content,
.mat-drawer-container {
  overflow: visible !important;
}

@media screen and (max-width: 599px) {
  .product-details-dialog {
    height: 100vh !important;
    width: 100vw !important;
    max-width: 100vw !important;
    padding: 0 !important;

    @keyframes slideDown {
      0% {
        transform: translate(100%, 0);
      }

      100% {
        transform: translate(0, 0);
      }
    }

    &.cdk-overlay-pane {
      transform: translate(100%, 0);
      animation: slideDown 0.2s both 0s linear;
    }

    .cdk-overlay-container > * {
      transition: none;
    }
  }
}

@media screen and (min-width: 960px) {
  .mat-badge-content {
    left: -2px !important;
    bottom: -5px !important;
  }
}

.mat-tab-link {
  min-width: fit-content !important;
}

.mat-tab-links {
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

nav.mat-tab-nav-bar {
  .mat-tab-link {
    font-size: 16px;
    opacity: 0.7 !important;

    &.mat-tab-label-active {
      opacity: 1 !important;
    }
  }
}


.color-red {
  color: #d32f2f;
}