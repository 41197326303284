@font-face {
  font-family: "origami";
  src: url("fonts/origami.eot");
  src: url("fonts/origami.eot?#iefix") format("embedded-opentype"),
    url("fonts/origami.woff") format("woff"),
    url("fonts/origami.ttf") format("truetype"),
    url("fonts/origami.svg#origami") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class*="origami-"]:before {
  display: inline-block;
  font-family: "origami";
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.origami-futomaki:before {
  content: "\0041";
}

.origami-mini_rolls:before {
  content: "\0042";
}

.origami-pasta:before {
  content: "\0043";
}

.origami-pizza:before {
  content: "\0044";
}

.origami-rolls:before {
  content: "\0045";
}

.origami-salad:before {
  content: "\0046";
}

.origami-sandwich_rolls:before {
  content: "\0047";
}

.origami-sets:before {
  content: "\0048";
}

.origami-soups:before {
  content: "\0049";
}

.origami-sushi:before {
  content: "\004a";
}

.origami-tempura:before {
  content: "\004b";
}

.origami-udon:before {
  content: "\004c";
}

.origami-baked:before {
  content: "\004d";
}

.origami-water:before {
  content: "\004e";
}

.origami-hot_rolls:before {
  content: "\004f";
}

.origami-spicy_rolls:before {
  content: "\0050";
}

.origami-rolls_without_rice:before {
  content: "\0051";
}

.origami-sauces:before {
  content: "\0052";
}

.origami-chef_rolls:before {
  content: "\0053";
}

.origami-dessert:before {
  content: "\0054";
}
